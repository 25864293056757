import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Figure from "../components/figure"
import DoctorCard from "../components/doctor-card"
import { StaticImage } from "gatsby-plugin-image"
import Packets from "../components/packets"
import Pricelist from "../components/pricelist"
import Search from "../components/search"

export default function Diagnostics({ data }) {
  const {
    title,
    page_title,
    page_description,
    slug,
    price_tables,
    description,
    how_to_prepare_yourself,
    when,
    mail,
    phone,
    localization,
    visit,
    doctors,
    offer,
    packets,
    webzona
  } = data.page

  let doctorsMap = {}
  console.log(doctors)
  if (data.doctors.edges) {
    data.doctors.edges.map(({ node }) => doctorsMap[node.fields.slug.replace(/\//g, "")] = node)
  }

  const seo = {
    title: page_title,
    description: page_description,
    url: "https://medicusdukla.pl" + slug
  }

  function chunkArray(arr, chunkCount) {
    const chunks = []
    while (arr.length) {
      const chunkSize = Math.ceil(arr.length / chunkCount--)
      const chunk = arr.slice(0, chunkSize)
      chunks.push(chunk)
      arr = arr.slice(chunkSize)
    }
    return chunks
  }

  let table_items = []
  if (price_tables) {
    price_tables.map(table => table_items = [...table_items, ...table.items])
  }

  const priceColumnsDesktop = price_tables ? chunkArray(price_tables, 3) : []
  const priceColumnsTablet = price_tables ? chunkArray(price_tables, 2) : []

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <div className="o-diagnostics">
        <div data-g="container grid">
          <div data-g="4 6@md 5@lg">
            <span className="header-overtext">Diagnostyka</span>
            <h1 className="a-heading-medium">{title}</h1>
            <div dangerouslySetInnerHTML={{__html: webzona}}/>
            <p className="o-diagnostics__description" dangerouslySetInnerHTML={{__html: description}} />

            {how_to_prepare_yourself && (
              <div className="o-diagnostics__prepare">
                <h3>Jak się przygotować do badania?</h3>
                <p>
                  {how_to_prepare_yourself}
                </p>
              </div>
            )}
            {doctors && doctors.map((doctor, key) => {
              const doc = doctorsMap[doctor.id]
              return (
                <div data-g="4 6@md 4@lg">
                  <DoctorCard
                    initials={doc.initials}
                    role={doc.specialization}
                    name={doc.name}
                    phone={doctor.phone}
                    mail={doctor.mail}
                    when={doctor.when}
                    key={key}
                  />
                </div>
              )
            })}
          </div>
          <div data-g="4 offset-8@md 6@md offset-9@lg 4@lg">
            {when &&
            <Figure src="/images/diagnostyka/clock.svg" text={when} />
            }
            {mail &&
            <Figure src="/images/diagnostyka/mail.svg" text={mail} />
            }
            {phone &&
            <Figure src="/images/diagnostyka/phone.svg" text={phone} />
            }
            {localization &&
            <Figure src="/images/diagnostyka/localization.svg" text={localization} />
            }
            <br />
            {/*<div className="o-diagnostics__reserve">*/}
            {/*  <a className="a-button" href="TODO" target="_blank" rel="nofollow">Umów wizytę</a> <br />*/}
            {/*</div>*/}
            {visit &&
            <div>
              <br /><br />
              <a className="a-arrow-link" href={visit} target="_blank" rel="nofollow noreferrer">
                Znajdź najbliższy gabinet
                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                  <path d="M10.7999 6.56006L0 6.56006" stroke="#00B8BC" strokeWidth="2" strokeMiterlimit="10" />
                  <path d="M6.30029 11L10.8 6.56018L6.30029 2.12036" stroke="#00B8BC" strokeWidth="2"
                        strokeMiterlimit="10" strokeLinecap="square" />
                </svg>
              </a>
            </div>
            }
          </div>
        </div>
        {offer && offer.map((offer, key) => (
          <div data-g="container" key={key}>
            <h3 className="o-diagnostics__offer-heading">{offer.title}</h3>
            <div data-g="grid">
              {offer.doctors.length > 0 && offer.doctors.map((doctor, subkey) => {
                const doc = doctorsMap[doctor.id]
                return (
                  <div data-g={`4 6@md 5@xl ${subkey % 2 === 1 && 'offset-7@xl'}`} key={subkey}>
                    <DoctorCard
                      initials={doc.initials}
                      role={doc.specialization}
                      name={doc.name}
                      phone={doctor.phone}
                      mail={doctor.mail}
                      when={doctor.when}
                      when_multiple={doctor.when_multiple}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        ))}
        {packets && (
          <div data-g="container">
            {packets.map((packet, key) => (
              <Packets image={packet.image} heading={packet.title} items={packet.items} key={key} />
            ))}
          </div>
        )}
        {priceColumnsDesktop.length > 0 && (
          <div data-g="container grid">
            <div data-g="4 12@md">
              <Search items={table_items} />
            </div>
            {priceColumnsDesktop.map((col, key) => (
              <div data-g="hide show@lg 4@lg">
                {col.map((table, key) => (
                  <Pricelist heading={table.title} items={table.items} key={key} />
                ))}
              </div>
            ))}
            {priceColumnsTablet.map((col, key) => (
              <div data-g="4 6@md hide@lg">
                {col.map((table, key) => (
                  <Pricelist heading={table.title} items={table.items} key={key} />
                ))}
              </div>
            ))}
          </div>
        )}
        <div data-g="container">
          {packets && (
            <div className="o-diagnostics__additional">*Dodatkowe informacje na temat cen pozostałych badań oraz czasu
              oczekiwania na wynik dostępne są w
              Punkcie Pobrań.</div>
          )}
          <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz" />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query DiagnostykaPost($slug: String) {
        page: diagnostykaYaml(slug: { eq: $slug }) {
            page_title
            page_description
            title
            slug
            where
            description
            how_to_prepare_yourself
            when
            mail
            phone
            localization
            webzona
            doctors {
                id
                phone
                mail
            }
            visit
            packets {
                title
                image
                items {
                    type
                    price
                }
            }
            price_tables {
                title
                items {
                    type
                    price
                }
            }
        }
        doctors: allLekarzeYaml {
            edges {
                node {
                    name
                    initials
                    specialization
                    description
                    fields {
                        slug
                    }
                }
            }
        }
    }`
